import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Image, Section, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Маркетингова агенція Oujin
			</title>
			<meta name={"description"} content={"Ласкаво просимо до маркетингової агенції Oujin, цифрового перехрестя, де стратегія зустрічається з інноваціями, і де ваш бізнес знаходиться в центрі нашої уваги."} />
			<meta property={"og:title"} content={"Головна | Маркетингова агенція Oujin"} />
			<meta property={"og:description"} content={"Ласкаво просимо до маркетингової агенції Oujin, цифрового перехрестя, де стратегія зустрічається з інноваціями, і де ваш бізнес знаходиться в центрі нашої уваги."} />
			<meta property={"og:image"} content={"https://oujinscundy.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://oujinscundy.com/img/icon-581597.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://oujinscundy.com/img/icon-581597.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://oujinscundy.com/img/icon-581597.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://oujinscundy.com/img/icon-581597.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://oujinscundy.com/img/icon-581597.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://oujinscundy.com/img/icon-581597.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" background="#0070d4" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-padding="80px 25px 80px 25px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Link
							href="#"
							color="#e8e4e3"
							margin="0px 0px 25px 0px"
							font="normal 400 16px/1.5 --fontFamily-sans"
							letter-spacing="1px"
							text-decoration-line="initial"
						>
							Маркетингова агенція
						</Link>
						<Text
							margin="0px 0px 20px 0px"
							font="normal 400 66px/1.2 --fontFamily-serifGaramond"
							sm-font="normal 700 42px/1.2 --fontFamily-sans"
							color="--light"
							lg-margin="0px 0px 35px 0px"
						>
							{" "}Oujin
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						sm-flex-direction="column"
						align-items="flex-end"
					>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							width="70%"
							lg-width="60%"
							sm-width="100%"
							sm-margin="0px 0px 25px 0px"
							justify-content="flex-end"
						>
							<Text margin="0px 0px 25px 0px" font="--lead" color="#e8e4e3" padding="0px 35px 0px 0px">
								Ласкаво просимо до маркетингової агенції Oujin, цифрового перехрестя, де стратегія зустрічається з інноваціями, і де ваш бізнес знаходиться в центрі нашої уваги.
							</Text>
							<Link href="/contact" color="#e8e4e3">
								Зв'яжіться з нами
							</Link>
						</Box>
					</Box>
				</Box>
				<Image
					src="https://oujinscundy.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="64px 0" sm-padding="40px 0" font="--base" color="--dark">
			<Override slot="SectionContent" lg-align-items="center" />
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap" lg-width="100%">
				<Box
					width="20%"
					lg-width="100%"
					lg-order="-1"
					padding="0px 0px 0px 16px"
					lg-padding="0px 0px 0px 0px"
					lg-margin="0px 0px 32px 0px"
				>
					<Text as="h2" font="--headline2" md-font="--headline3" margin="0">
						Чому Oujin?
					</Text>
				</Box>
				<Box
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					padding="0px 16px 0px 0px"
					width="80%"
					lg-padding="0px 0px 0px 0px"
					lg-width="100%"
					sm-grid-template-columns="1fr"
				>
					<Box
						padding="20px 20px 20px 20px"
						background="--color-lightD1"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Text
							as="h3"
							font="--headline3"
							margin="20px 0 5px 0"
							text-align="center"
							color="--darkL2"
						>
							Індивідуальна стратегія
						</Text>
						<Text color="--grey" margin="0">
							Кожен бізнес унікальний, як і наші стратегії. Розроблені відповідно до ваших конкретних цілей.
						</Text>
					</Box>
					<Box
						padding="20px 20px 20px 20px"
						background="--color-lightD1"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Text
							as="h3"
							font="--headline3"
							margin="20px 0 5px 0"
							text-align="center"
							color="--darkL2"
						>
							На основі даних
						</Text>
						<Text color="--grey" margin="0">
							Ми віримо, що цифри говорять. Наші кампанії побудовані на надійних даних та аналітиці.
						</Text>
					</Box>
					<Box
						padding="20px 20px 20px 20px"
						background="--color-lightD1"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Text
							as="h3"
							font="--headline3"
							margin="20px 0 5px 0"
							text-align="center"
							color="--darkL2"
						>
							Прозора комунікація
						</Text>
						<Text color="--grey" margin="0">
							З нами ви ніколи не залишаєтесь у невіданні. Відкриті лінії зв'язку тримають вас в курсі подій.
						</Text>
					</Box>
					<Box
						padding="20px 20px 20px 20px"
						background="--color-lightD1"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Text
							as="h3"
							font="--headline3"
							margin="20px 0 5px 0"
							text-align="center"
							color="--darkL2"
						>
							Гнучка методологія
						</Text>
						<Text color="--grey" margin="0">
							Тенденції ринку змінюються. Так само, як і ми. Швидко адаптуючись, ми зберігаємо ваші кампанії свіжими та актуальними.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="150px 0 150px 0"
			min-height="100vh"
			background="linear-gradient(180deg,--color-light 0%,transparent 86.7%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://oujinscundy.com/img/2.jpg) 0% 0% /cover no-repeat scroll padding-box"
			lg-padding="90px 0 90px 0"
			quarkly-title="404-2"
		>
			<Override slot="SectionContent" max-width="1220px" justify-content="flex-start" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					color="--dark"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline2"
					letter-spacing="-0.025em"
					lg-margin="0px 0px 16px 0px"
				>
					Цифровий всесвіт на кінчиках ваших пальців
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--dark"
					margin="0px 0px 36px 0px"
					text-align="center"
					lg-margin="0px 0px 24px 0px"
				>
					Oujin - це не звичайна маркетингова агенція. Ми процвітаємо, створюючи індивідуальні стратегії цифрового маркетингу, які резонують з вашою цільовою аудиторією. Наш підхід простий, але ефективний: ми слухаємо, ми аналізуємо, ми виконуємо.
				</Text>
				<LinkBox
					flex-direction="row"
					padding="12px 8px 12px 8px"
					transition="--opacityOut"
					hover-opacity="0.7"
					lg-margin="0px 0px 20px 0px"
					href="/contact"
					border-style="solid"
					border-width="2px"
					border-color="--color-dark"
					border-radius="15px"
				>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" padding="0px 20px 0px 20px">
						Зв'яжіться з нами
					</Text>
				</LinkBox>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});